<template>
  <b-row>
    <b-col cols="6">
      <b-list-group>
        <b-list-group-item
          v-for="(item, i) in productosEdit"
          :key="i"
          button
          class="d-flex justify-content-between align-items-center"
        >
          <b-badge
            variant="primary"
            pill
            class="badge-round"
          >
            {{ item.uds }} uds.
          </b-badge>
          <span>{{ item.ref }}</span>
          <b-button-group>
            <b-button
              v-if="!isDisabled"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="btn-icon"
              variant="outline-secondary"
              :disabled="disabledActions"
              @click.stop="incrementDecrement(i, -1)"
            >
              <feather-icon icon="MinusIcon" />
            </b-button>
            <b-button
              v-if="!isDisabled"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="btn-icon"
              variant="outline-secondary"
              :disabled="disabledActions"
              @click.stop="incrementDecrement(i, 1)"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-button-group>
          <b-button
            v-if="!isDisabled"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            :disabled="disabledActions"
            @click.stop="deleteproductoData(i)"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </b-list-group-item>
      </b-list-group>
    </b-col>

    <b-col md="6">
      <b-form
        v-if="!isDisabled"
        class="p-2"
        @submit.prevent="addProduct"
      >
        <b-form-group
          label="Ref."
          label-for="ep-ref"
        >
          <v-select
            v-model="productoData.ref"
            label="title"
            :options="productosList"
            :clearable="false"
            input-id="ep-ref"
            @input="setSelected"
          />
        </b-form-group>
        <b-form-group
          label="Unidades"
          label-for="ep-uds"
        >
          <b-form-input
            id="ep-uds"
            v-model="productoData.uds"
            type="number"
            :disabled="!productoData.ref"
          />
        </b-form-group>
        <b-button
          variant="warning"
          :disabled="!productoData.ref || !parseInt(productoData.uds, 10)"
          @click="addProduct"
        >
          <span class="text-nowrap">Add Product</span>
        </b-button>
        <!-- <b-form-group
          label="Ref."
          label-for="ep-ref"
        >
          <b-form-input
            id="ep-ref"
            v-model="newProduct.ref"
          />
        </b-form-group> -->
        <!-- <b-form-group
          label="Unidades"
          label-for="ep-uds"
        >
          <b-form-input
            id="ep-uds"
            v-model="newProduct.uds"
            type="number"
            :disabled="!newProduct.ref"
          />
        </b-form-group> -->
        <!-- <b-button
          variant="warning"
          :disabled="!newProduct.ref || !parseInt(newProduct.uds, 10)"
          @click="addProduct"
        >
          <span class="text-nowrap">Add Product</span>
        </b-button> -->
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import {
  BListGroup, BListGroupItem, BBadge, BButton, BButtonGroup,
  BForm, BFormInput, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import usePedidos from '@/views/bimbo/pedidos/pedidos-all/usePedidos'
import usePedidosAddNew from './usePedidosAddNew'

export default {
  components: {
    BBadge,
    BListGroup,
    BListGroupItem,
    BButton,
    BButtonGroup,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    productos: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const {
      productoData,
      setSelected,
    } = usePedidosAddNew()

    const {
      productosList,
    } = usePedidos()

    return {
      productoData,
      setSelected,
      productosList,
    }
  },
  data() {
    return {
      newProduct: {
        ref: '',
        uds: 1,
      },
      productosEdit: [],
      status: 0,
    }
  },
  computed: {
    disabledActions() {
      return this.status !== 0
    },
  },
  watch: {
    estado() {
      // console.log('cambio...')
    },
  },
  mounted() {
    this.productosEdit = JSON.parse(JSON.stringify(this.productos))
    // this.status = this.estado
  },
  methods: {
    deleteproductoData(i) {
      // console.log(`eliminar el producto ${i}`)
      this.productosEdit.splice(i, 1)
      this.$emit('suma', this.productosEdit)
    },
    incrementDecrement(i, value) {
      const nuevo = this.productosEdit[i].uds + value
      // console.log(`sumar al producto ${i} ${value} unidades`)
      if (nuevo > 0) {
        this.productosEdit[i].uds = nuevo
        this.$emit('suma', this.productosEdit)
      }
    },
    addProduct() {
      // console.log('agregando...')
      // console.log(this.newProduct)
      this.newProduct = JSON.parse(JSON.stringify(this.productoData))
      const { ref, uds } = this.newProduct
      const udsNumber = parseInt(uds, 10)
      if (!ref || !udsNumber) return
      this.productosEdit.push({
        ref,
        uds: udsNumber,
      })
      this.$emit('suma', this.productosEdit)
      this.newProduct = {
        ref: '',
        uds: 1,
      }
    },
  },
}
</script>
