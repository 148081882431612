<template>

  <div>

    <producto-add-new
      :is-add-new-producto-sidebar-active.sync="isAddNewProductoSidebarActive"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-title>
        <h1 class="mx-5 pt-2">
          Inventarios
        </h1>
      </b-card-title>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="10"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="inventPerPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="inventPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries of {{ itemsFilterInventarios.length }}</label>
          </b-col>

          <b-col
            cols="2"
          >
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon"
              @click="exportToExcel"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <!-- <b-button
                variant="danger"
                :disabled="false"
                @click="exportToExcel"
              >
                <span class="text-nowrap">DownloadIcon</span>
              </b-button> -->
              <b-button
                variant="primary"
                :disabled="false"
                :to="{ name: 'bimbo-inventario-new' }"
              >
                <!-- @click="isAddNewProductoSidebarActive = true" -->
                <span class="text-nowrap">Add Inventory</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-col
        cols="12"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="itemsFilteredsInventarios"
          responsive
          :fields="inventTableColumns"
          primary-key="id"
          :sort-by.sync="inventSortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="inventIsSortDirDesc"
        >

          <!-- Column: ID -->
          <template #cell(id)="data">
            <b-media vertical-align="center">
              <b-link
                class="font-weight-bold d-block text-nowrap"
                :to="{ name: 'bimbo-inventario-view', params: { id: data.item.id } }"
              >
                {{ data.item.id }}
              </b-link>
              <!-- <small class="text-muted">{{ data.item.id.slice(-5) }}</small> -->
            </b-media>
          </template>

          <!-- Column: Fecha -->
          <template #cell(fecha)="data">
            <div class="text-nowrap">
              <span class="text-center font-weight-bolder">{{ new Date(parseInt(data.item.fecha, 10)).toLocaleDateString() }} {{ new Date(parseInt(data.item.fecha, 10)).toLocaleTimeString() }}</span>
            </div>
            <!-- <b-media vertical-align="center">
              <small class="text-muted">{{ new Date(parseInt(data.item.fecha)).toLocaleDateString() }}</small>
            </b-media> -->
          </template>

          <!-- Column: Titulo -->
          <template #cell(titulo)="data">
            <div class="text-nowrap">
              <span class="text-left font-weight-bolder">{{ data.item.titulo }}</span>
            </div>
            <b-media vertical-align="center">
              <small class="text-muted">{{ data.item.notas }}</small>
            </b-media>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </template>

        </b-table>
      </b-col>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="inventCurrentPage"
              :total-rows="itemsFilterInventarios.length"
              :per-page="inventPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <producto-edit
      :producto="productoSelect"
      :is-edit-producto.sync="isEditProducto"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BDropdown, BDropdownItem, BPagination, BMedia, BLink,
  BCardTitle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import { paginateArray, sortCompare } from '@/libs/filter-data'
import Ripple from 'vue-ripple-directive'
import ProductoAddNew from '@/views/bimbo/components/ProductoAddNew.vue'
import ProductoEdit from '@/views/bimbo/components/ProductoEdit.vue'
import useProductos from '../useProductos'
// import { export_json_to_excel } from '@/libs/Export2Excel'

export default {
  components: {
    ProductoAddNew,
    ProductoEdit,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BCardTitle,
    // BAvatar,
    BLink,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Export Excell
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'csv',
    }
  },

  setup() {
    const {
      // Nuevo Producto
      isAddNewProductoSidebarActive,
      // UI
      isEditProducto,
      productoSelect,
      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      inventPerPage,
      inventCurrentPage,
      inventPerPageOptions,
      inventSortBy,
      inventIsSortDirDesc,
      inventTableColumns,
      // Datos
      productos,
      productosList,
      itemsFilterProductos,
      itemsFiltereds,
      inventarios,
      itemsFilterInventarios,
      itemsFilteredsInventarios,
      // methods
      fetchProductos,
      editProducto,
      addProducto,
      exportToExcel,
      fetchInventarios,
      unsubscribeInventarios,
    } = useProductos()

    return {
      isEditProducto,
      productoSelect,
      // Nuevo Producto
      isAddNewProductoSidebarActive,
      // UI
      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      inventPerPage,
      inventCurrentPage,
      inventPerPageOptions,
      inventSortBy,
      inventIsSortDirDesc,
      inventTableColumns,
      // Datos
      productos,
      productosList,
      itemsFilterProductos,
      itemsFiltereds,
      inventarios,
      itemsFilterInventarios,
      itemsFilteredsInventarios,
      // methods
      fetchProductos,
      editProducto,
      addProducto,
      exportToExcel,
      fetchInventarios,
      unsubscribeInventarios,
    }
  },

  created() {
    this.fetchInventarios()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
