<template>
  <b-sidebar
    id="add-new-cliente-sidebar"
    :visible="isAddNewProductoSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-producto-sidebar-active', val)"
  >
    <!-- @hidden="resetForm" -->
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Client
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <template v-if="false">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <!-- @reset.prevent="resetForm" -->

            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              name="Full Name"
              rules="required"
            >
              <b-form-group
                label="Full Name"
                label-for="full-name"
              >
                <b-form-input
                  id="full-name"
                  v-model="userData.fullName"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="John Doe"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Username -->
            <validation-provider
              #default="validationContext"
              name="Username"
              rules="required|alpha-num"
            >
              <b-form-group
                label="Username"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  v-model="userData.username"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Company -->
            <validation-provider
              #default="validationContext"
              name="Contact"
              rules="required"
            >
              <b-form-group
                label="Contact"
                label-for="contact"
              >
                <b-form-input
                  id="contact"
                  v-model="userData.contact"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Company -->
            <validation-provider
              #default="validationContext"
              name="Company"
              rules="required"
            >
              <b-form-group
                label="Company"
                label-for="company"
              >
                <b-form-input
                  id="company"
                  v-model="userData.company"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Country -->
            <validation-provider
              #default="validationContext"
              name="Country"
              rules="required"
            >
              <b-form-group
                label="Country"
                label-for="country"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.country"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countries"
                  :clearable="false"
                  input-id="country"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Plan -->
            <!-- <validation-provider
              #default="validationContext"
              name="Plan"
              rules="required"
            >
              <b-form-group
                label="Plan"
                label-for="plan"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.currentPlan"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="planOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="plan"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
      <template v-else>
        <div class="mx-3 my-3">
          <h6>Sección en desarrollo</h6>
        </div>
      </template>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewProductoSidebarActive',
    event: 'update:is-add-new-producto-sidebar-active',
  },
  props: {
    isAddNewProductoSidebarActive: {
      type: Boolean,
      required: true,
    },
    // planOptions: {
    //   type: Array,
    //   required: false,
    // },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      ruta: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('bimbo-clientes/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-producto-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      // resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      // resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
