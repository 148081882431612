// src/utils/gmaps.js

// Your personal API key.
// Get it here: https://console.cloud.google.com/google/maps-apis
const API_KEY = 'AIzaSyC3EYDV1IM_OjkKBr4qZtf42A3g43F8azY'
const CALLBACK_NAME = 'gmapsCallback'

let initialized = !!window.google
let resolveInitPromise
let rejectInitPromise
let google
let directionsService
let autocompleteService
let geocoderService
// let placesService
// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve
  rejectInitPromise = reject
})

function gmapsInit() {
  // If Google Maps already is initialized
  // the `initPromise` should get resolved
  // eventually.
  if (initialized) return initPromise

  initialized = true
  // The callback function is called by
  // the Google Maps script if it is
  // successfully loaded.
  window[CALLBACK_NAME] = () => resolveInitPromise(window.google)

  // We inject a new script tag into
  // the `<head>` of our HTML to load
  // the Google Maps script.
  const script = document.createElement('script')
  script.async = true
  script.defer = true
  script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${API_KEY}&callback=${CALLBACK_NAME}`
  script.onerror = rejectInitPromise
  document.querySelector('head').appendChild(script)

  return initPromise
}

function gmapDirServ(data) {
  return new Promise((resolve, reject) => {
    try {
      if (!directionsService) {
        directionsService = new google.maps.DirectionsService()
      }
      directionsService.route(
        {
          origin: data.start,
          destination: data.end,
          waypoints: data.waypts,
          optimizeWaypoints: true,
          travelMode: google.maps.TravelMode.BICYCLING,
        },
        (response, status) => {
          // console.log(response)
          if (status === 'OK' && response) {
            const route = response.routes[0]
            // console.log(route.waypoint_order)
            // const order = route.waypoint_order
            resolve(route)
          }
          resolve({})
        },
      )
    } catch (error) {
      reject(error)
    }
  })
}

export const directions = async data => {
  try {
    if (!google) {
      google = await gmapsInit()
    }
    // const google = await gmapsInit()
    const resultado = await gmapDirServ(data)
    return resultado
  } catch (error) {
    throw new Error(error)
  }
}

function gmapAutocompleteServ(input) {
  return new Promise((resolve, reject) => {
    try {
      if (!autocompleteService) {
        autocompleteService = new google.maps.places.AutocompleteService()
      }
      // autocompleteService.getQueryPredictions(
      autocompleteService.getPlacePredictions(
        {
          input,
        },
        (response, status) => {
          if (status === 'OK' && response) {
            const result = response
            // console.log(route.waypoint_order)
            // const order = route.waypoint_order
            resolve(result)
          }
          resolve({})
        },
      )
    } catch (error) {
      reject(error)
    }
  })
}

export const places = async input => {
  try {
    if (!google) {
      google = await gmapsInit()
    }
    // const google = await gmapsInit()
    const resultado = await gmapAutocompleteServ(input)
    // console.log(resultado1)
    // const resultado = await gmapPlaceService({
    //   placeId: 'Eh5DYWxsZSBTZXJyYW5vLCBNYWRyaWQsIEVzcGHDsWEiLiosChQKEglRb4AL6ihCDREnK9oSt8DvbBIUChIJgTwKgJcpQg0RaSKMYcHeNsQ',
    // })
    return resultado
  } catch (error) {
    throw new Error(error)
  }
}

function gmapGeocoderServ(placeId) {
  return new Promise((resolve, reject) => {
    try {
      if (!geocoderService) {
        geocoderService = new google.maps.Geocoder()
      }
      // autocompleteService.getQueryPredictions(
      geocoderService.geocode(
        {
          placeId,
        },
        (response, status) => {
          if (status === 'OK' && response) {
            const result = response
            // console.log(route.waypoint_order)
            // const order = route.waypoint_order
            resolve(result)
          }
          resolve({})
        },
      )
    } catch (error) {
      reject(error)
    }
  })
}

export const geo = async placeId => {
  try {
    if (!google) {
      google = await gmapsInit()
    }
    // const google = await gmapsInit()
    const resultado = await gmapGeocoderServ(placeId)
    // console.log(resultado1)
    // const resultado = await gmapPlaceService({
    //   placeId: 'Eh5DYWxsZSBTZXJyYW5vLCBNYWRyaWQsIEVzcGHDsWEiLiosChQKEglRb4AL6ihCDREnK9oSt8DvbBIUChIJgTwKgJcpQg0RaSKMYcHeNsQ',
    // })
    return resultado
  } catch (error) {
    throw new Error(error)
  }
}

// export default directions
